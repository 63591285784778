export const dictionary = {
    login: {
        pl: 'Logowanie',
        cs: 'Přihlášení',
        sk: 'Prihlásenie',
        en: 'Log in',
        hu: 'BEJELENTKEZÉS',
        ro: 'Logare',
    },
    register: {
        pl: 'Rejestracja',
        cs: 'Registrace',
        sk: 'Registrácia',
        en: 'Register',
        hu: 'REGISZTRÁCIÓ',
        ro: 'Înregistrare',
    },
    username: {
        pl: 'nazwa użytkownika',
        cs: 'uživatelské jméno',
        sk: 'uživatelské jméno',
        en: 'user name',
        hu: 'felhasználónév',
        ro: 'nume de utilizator',
    },
    orderCourse: {
        pl: 'Zamów kurs',
        cs: 'Order a course',
        sk: 'Order a course',
        en: 'Order a course',
        hu: 'Rendeljen egy tanfolyamot',
        ro: 'Comandați un curs',
    },
    aboutElingo: {
        pl: 'O polyglot',
        cs: 'About polyglot',
        sk: 'About polyglot',
        en: 'About polyglot',
        hu: 'About polyglot',
    },
    aboutUs: {
        pl: 'O nas',
        cs: 'About us',
        sk: 'About us',
        en: 'About us',
        hu: 'About us',
        ro: 'Despre noi',
    },
    check: {
        pl: 'Sprawdź',
        cs: 'Podívejte se',
        sk: 'Skontrolujte',
        en: 'Check',
        hu: 'Jelölje be',
        ro: 'Verifica',
    },
    homePageSloganFirstLine: {
        pl: 'Nauka nowego języka?',
        cs: 'Učíte se cizí jazyk?',
        sk: 'Objavujete nový jazyk?',
        en: 'Discovering a new language?',
        hu: 'Új nyelvet fedez fel?',
        ro: 'Descoperiți o nouă limbă?',
    },
    homePageSloganSecondLine: {
        pl: 'To tylko 2 kroki!',
        cs: 'Stačí jen 2 kroky!',
        sk: 'Stačia na to len 2 kroky!',
        en: 'It only takes 2 steps!',
        hu: 'Mindössze 2 lépés!',
        ro: 'Este nevoie doar de 2 pași!',
    },
    loginBoxDescription: {
        pl: 'Masz już konto na platformie i chcesz rozpocząć naukę? Twoja przygoda z językiem zaczyna się właśnie tutaj!',
        cs: 'Již máte na platformě účet a chcete se začít učit?  Vaše jazyková dobrodružství právě začínají!',
        sk: 'Už máte konto na platforme a chcete sa začať učiť?  Vaše jazykové dobrodružstvo sa začína práve tu!',
        en: 'Already have an account on the platform and want to begin learning?  Your language adventure starts right here!',
        hu: 'Már van fiókod a platformon, és szeretnéd elkezdeni a tanulást? Nyelvi kalandod itt és most kezdődik!',
        ro: 'Aveți deja un cont pe platformă și doriți să începeți să învățați?  Aventura ta lingvistică începe chiar aici!',
    },
    registerBoxDescription: {
        pl: 'Masz już kod do rejestracji? Świetnie! W tym miejscu możesz założyć konto!',
        cs: 'Máte již registrační kód? Skvělé! Nyní si zde můžete vytvořit účet!',
        sk: 'Máte už registračný kód? Skvelé! Tu si môžete vytvoriť účet!',
        en: 'Do you already have a registration code? Great! Here you can create an account!',
        hu: 'Van már regisztrációs kódod? Szuper! Itt tudsz fiókot létrehozni!',
        ro: 'Ați vorbit deja cu unul dintre consultanții noștri lingvistici și ați primit pachetul cu un cod? Minunat! Aici vă puteți crea un cont!',
    },
    learnMoreHeader: {
        pl: 'Chcesz dowiedzieć się więcej?',
        cs: 'Chtěli byste se naučit více?',
        sk: 'Chcete sa naučiť viac?',
        en: 'Would you like to learn more?',
        hu: 'Szeretne többet megtudni?',
        ro: 'Doriți să învățați mai mult?',
    },
    learnMoreDescription: {
        pl: 'Czym jest polyglot? Co myślą o nim kursanci? Dlaczego warto?',
        cs: 'Co je polyglot? Jaké jsou názory studentů? Proč to stojí za to?',
        sk: 'Čo je to polyglot? Čo si o ňom myslia používatelia kurzov? Prečo sa oplatí?',
        en: 'What is polyglot? What do course users think about it? Why is it worth it?',
        hu: 'Mi az polyglot? Mit gondolnak róla a kurzus felhasználói? Miért éri meg?',
        ro: 'Ce înseamnă poliglot? Ce părere au utilizatorii de cursuri despre el? De ce merită?',
    },
    learnMoreButton: {
        pl: 'więcej',
        cs: 'více',
        sk: 'viac',
        en: 'more',
        hu: 'több',
        ro: 'mai multe',
    },
    mainPageWelcomeBoxHeaderOne: {
        pl: 'Cześć',
        cs: 'Ahoj',
        sk: 'Ahoj',
        en: 'Hi',
        hu: 'Szia',
        ro: 'Bună ziua',
    },
    mainPageWelcomeBoxHeaderTwo1: {
        pl: 'Świetnie Ci idzie!',
        cs: 'Vedeš si skvěle!',
        sk: 'Darí sa ti skvele',
        en: "You're doing great",
        hu: 'Jól csinálod',
        ro: 'Te descurci foarte bine',
    },
    mainPageWelcomeBoxHeaderTwo2: {
        pl: 'Niezła robota!',
        cs: 'Dobrá práce',
        sk: 'Dobrá práca',
        en: 'Good job',
        hu: 'Jó munka',
        ro: 'Bună treabă',
    },
    mainPageWelcomeBoxHeaderTwo3: {
        pl: 'Rozwijasz się!',
        cs: 'Zlepšuješ se',
        sk: 'Zlepšujete sa',
        en: "You're improving",
        hu: 'Fejlődsz',
        ro: 'Te îmbunătățești.',
    },
    mainPageWelcomeBoxTextFirstLine1: {
        pl: 'Znajomość języków obcych jest nie tylko przydatna, ale wręcz niezbędna do osiągnięcia sukcesu w życiu.',
        cs: 'Znalost cizích jazyků je nejen užitečná, ale také nezbytná k dosažení životního úspěchu.',
        sk: 'Znalosť cudzích jazykov je nielen užitočná, ale aj nevyhnutná pre úspech v živote.',
        en: 'Knowledge of foreign languages is not only useful, but actually necessary for success in life.',
        hu: 'A külföldi nyelvek ismerete nemcsak hasznos, hanem szükséges is az életben való sikerhez.',
        ro: 'Cunoașterea limbilor străine nu este doar utilă, ci chiar necesară pentru a avea succes în viață.',
    },
    mainPageWelcomeBoxTextFirstLine2: {
        pl: 'Osoby, które uczą się języków obcych łatwiej zapamiętują i koncentrują się na zadaniach, a także są lepszymi słuchaczami.',
        cs: 'Lidé, kteří mluví více než jedním jazykem, mají lepší paměť, lepší soustředění, schopnost multitaskingu a lepší schopnost naslouchat.',
        sk: 'Ľudia, ktorí ovládajú viac ako jeden jazyk, majú lepšiu pamäť, lepšiu koncentráciu, schopnosť vykonávať viac úloh naraz a lepšie počúvajú.',
        en: 'People who speak more than one language have improved memory, enhanced concentration, ability to multitask, and better listening skills.',
        hu: 'Azok az emberek, akik több nyelvet beszélnek, javítják a memóriájukat, javítják a koncentrációjukat, képesek több feladatot egyszerre elvégezni, és jobb hallási képességeik vannak.',
        ro: 'Persoanele care vorbesc mai multe limbi străine au o memorie îmbunătățită, o concentrare sporită, capacitatea de a face mai multe lucruri în același timp și abilități de ascultare mai bune.',
    },
    mainPageWelcomeBoxTextFirstLine3: {
        pl: 'Nauka nowego języka to niezwykłe doświadczenie. Poczuj radość, rozmawiając z kimś w jego ojczystym języku.',
        cs: 'Naučit se cizí jazyk si vyžaduje pevnou vůli. Výhodou je úžasný pocit úspěchu, který pocítíte při konverzaci s někým v jeho rodném jazyce.',
        sk: 'Učiť sa cudzí jazyk znamená vystúpiť zo svojej komfortnej zóny. Pozitívom je úžasný pocit úspechu, ktorý pocítite, keď sa s niekým rozprávate v jeho rodnom jazyku.',
        en: 'Learning a language means moving out of your comfort zone. The upside is the amazing sense of accomplishment you’ll feel when conversing with someone in their native language.',
        hu: 'A nyelvtanulás azt jelenti, hogy kilépsz a komfortzónádból. Az előny az, hogy elképesztő érzés lesz, amikor valakivel anyanyelvén beszélgetsz.',
        ro: 'Învățarea unei limbi străine înseamnă să ieși din zona ta de confort. Partea bună este sentimentul uimitor de realizare pe care îl veți simți atunci când veți conversa cu cineva în limba sa maternă.',
    },
    mainPageWelcomeBoxTextSecondLine: {
        pl: 'W następnej lekcji nauczysz się:',
        cs: 'V příští lekci se naučíte:',
        sk: 'V nasledujúcej lekcii sa naučíte:',
        en: 'In the next lesson you will learn:',
        hu: 'A következő leckében megtanulod:',
        ro: 'În următoarea lecție veți învăța:',
    },
    yourActivityBoxHeader: {
        pl: 'Twoja aktywność',
        cs: 'Tvoje aktivita',
        sk: 'Vaša aktivita',
        en: 'Your activity',
        hu: 'Az Ön tevékenysége',
        ro: 'Activitatea ta',
    },
    yourActivityBoxDaysOfLearning: {
        pl: 'dni nauki z rzędu',
        cs: 'Dny učení v řadě',
        sk: 'Dni učenia v rade',
        en: 'days of learning in a row',
        hu: 'napos tanulás',
        ro: 'zile de învățare la rând',
    },
    yourActivityBoxLearnedWords: {
        pl: 'poznanych dziś słów',
        cs: 'Nová slovíčka za dnešek',
        sk: 'slová, ktoré ste sa dnes naučili',
        en: 'the words you learned today',
        hu: 'a mai tanult szavak',
        ro: 'cuvintele pe care le-ați învățat astăzi',
    },
    level: {
        pl: 'Poziom',
        cs: 'Úroveň',
        sk: 'Úroveň',
        en: 'Level',
        hu: 'Szint',
        ro: 'Nivelul',
    },
    mainPageProgressBarDescription: {
        pl: 'Twój postęp na poziomie',
        cs: 'Váš pokrok na úrovni',
        sk: 'Váš pokrok na úrovni',
        en: 'Your progress on level',
        hu: 'A fejlődésed szintenként',
        ro: 'Progresul tău la nivel',
    },
    mainPageContinueLearningButtonText: {
        pl: 'Kontynuuj naukę',
        cs: 'Pokračovat v učení',
        sk: 'Pokračujte v učení',
        en: 'Continue learning',
        hu: 'Tovább a tanulással',
        ro: 'Continuați să învățați',
    },
    mainPageAllLessonsgButtontext: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'Az összes lecke',
        ro: 'Toate lecțiile',
    },
    mainPageActiveCourseHeader: {
        pl: 'Twój aktualny kurs',
        cs: 'Tvůj aktuální kurz',
        sk: 'Tvoj aktuálny kurz',
        en: 'Active course',
        hu: 'Aktuális kurzusod',
        ro: 'Cursul tău actual',
    },
    mainPageOtherCoursesButtonTextPre: {
        pl: 'Chcesz nauczyć się więcej?',
        cs: 'Chceš se naučit více?',
        sk: 'Chceš sa naučiť viac?',
        en: 'Want to learn more?',
        hu: 'Szeretnél többet megtanulni?',
        ro: 'Vrei săînveți mai mult?',
    },
    mainPageOtherCoursesButtonText: {
        pl: 'Aktywuj kolejny kurs >>',
        cs: 'Aktivuj další kurz >>',
        sk: 'Aktivuj ďalší kurz >>',
        en: 'Other Courses',
        hu: 'Aktiváld a következő kurzust >>',
        ro: 'Activează un alt curs >>',
    },
    mainPageFunFactsHeader: {
        pl: 'Ciekawostki',
        cs: 'Zajímavá fakta',
        sk: 'Zaujímavosti',
        en: 'Interesting facts',
        hu: 'Interesting facts',
        ro: 'Date interesante',
    },
    showPassword: {
        pl: 'Pokaż hasło',
        cs: 'Zobrazit heslo',
        sk: 'Ukázať heslo',
        en: 'Show password',
        hu: 'Mutasd a jelszót',
        ro: 'Afișați parola',
    },
    hidePassword: {
        pl: 'Ukryj hasło',
        cs: 'Skrýt heslo',
        sk: 'Skryť heslo',
        en: 'Hide password',
        hu: 'Jelszó elrejtése',
        ro: 'Ascundeți parola',
    },
    passwordsNotMatchAlert: {
        pl: 'Hasła nie są takie same',
        cs: 'Hesla nejsou stejná',
        sk: 'Heslá sa nezhodujú',
        en: 'Passwords do not match',
        hu: 'A jelszavak nem egyeznek',
        ro: 'Parolele nu sunt la fel',
    },
    progressModalMondayShort: {
        pl: 'pn.',
        cs: 'Po.',
        sk: 'Po,',
        en: 'Mon.',
        hu: 'Hé',
        ro: 'Luni',
    },
    progressModalTuesdayShort: {
        pl: 'wt.',
        cs: 'Út.',
        sk: 'Ut.',
        en: 'Tue.',
        hu: 'Ke',
        ro: 'Marți',
    },
    progressModalWednesdayShort: {
        pl: 'śr.',
        cs: 'St.',
        sk: 'St.',
        en: 'Wed.',
        hu: 'Sze',
        ro: 'Miercuri',
    },
    progressModalThursdayShort: {
        pl: 'czw.',
        cs: 'Čt.',
        sk: 'Stv.',
        en: 'Thu.',
        hu: 'Cs',
        ro: 'Joi',
    },
    progressModalFridayShort: {
        pl: 'pt.',
        cs: 'Pá.',
        sk: 'Pi.',
        en: 'Fri.',
        hu: 'Pé',
        ro: 'Vineri',
    },
    progressModalSaturdayShort: {
        pl: 'sob.',
        cs: 'So.',
        sk: 'So.',
        en: 'Sat.',
        hu: 'Szo',
        ro: 'Sâmbătă',
    },
    progressModalSundayShort: {
        pl: 'ndz.',
        cs: 'Ne.',
        sk: 'Ne.',
        en: 'Sun',
        hu: 'Vas',
        ro: 'Duminică',
    },
    progressModalChartTitle: {
        pl: 'Wykres Twoich postępów',
        cs: 'Graf vašeho pokroku',
        sk: 'Graf vášho pokroku',
        en: 'Charting your progress',
        hu: 'A fejlődés feltérképezése',
        ro: 'Graficul tău de progres',
    },
    back: {
        pl: 'Wstecz',
        cs: 'Zpět',
        sk: 'Späť',
        en: 'Back',
        hu: 'Vissza',
        ro: 'Înapoi',
    },
    emailLabel: {
        pl: 'adres e-mail',
        cs: 'e-mailová adresa',
        sk: 'emailová adresa',
        en: 'email address',
        hu: 'email cím',
        ro: 'adresa de e-mail',
    },
    passwordLabel: {
        pl: 'hasło',
        cs: 'heslo',
        sk: 'heslo',
        en: 'password',
        hu: 'jelszó',
        ro: 'parola',
    },
    activationCodeLabel: {
        pl: 'kod',
        cs: 'kód',
        sk: 'kód',
        en: 'code',
        hu: 'kód',
        ro: 'cod',
    },
    repeatPasswordLabel: {
        pl: 'powtórz hasło',
        cs: 'zopakovat heslo',
        sk: 'zopakujte heslo',
        en: 'repeat password',
        hu: 'jelszó újra',
        ro: 'repetați parola',
    },
    oldPasswordLabel: {
        pl: 'stare hasło',
        cs: 'staré heslo',
        sk: 'staré heslo',
        en: 'old password',
        hu: 'régi jelszó',
        ro: 'parola veche',
    },
    registerEmailDesc: {
        pl: 'Twój adres e-mail, którego będziesz używać do zalogowania się',
        cs: 'Vaše e-mailová adresa, kterou budete používat pro přihlášení',
        sk: 'Vaša e-mailová adresa, ktorú budete používať na prihlásenie',
        en: 'Your e-mail address, which you will use to log in',
        hu: 'az Ön e-mail címe, amelyet a bejelentkezéshez fog használni',
        ro: 'Adresa dvs. de e-mail pe care o veți folosi pentru a vă conecta',
    },
    loginEmailDesc: {
        pl: 'Twój adres e-mail, który podawałeś/aś przy założeniu konta',
        cs: 'Vaše e-mailová adresa, kterou jste zadali při vytváření účtu',
        sk: 'Vaša e-mailová adresa, ktorú ste uviedli pri vytváraní účtu',
        en: 'Your email address you entered when you created account',
        hu: 'A fiók létrehozásakor megadott e-mail címed',
        ro: 'Adresa dvs. de e-mail pe care ați furnizat-o la crearea contului dvs.',
    },
    activationCodeDesc: {
        pl: 'otrzymałeś/aś go w liście',
        cs: 'obdrželi jste ho v dopise',
        sk: 'dostali ste to v liste',
        en: 'you have received it in the letter',
        hu: 'evélben kaptad',
        ro: 'ați primit-o prin poștă',
    },
    registerPasswordDesc: {
        pl: 'Stwórz unikalne hasło, które łatwo zapamiętasz.',
        cs: 'vytvořte si vlastní jedinečné heslo, které si snadno zapamatujete',
        sk: 'vytvorte si svoje jedinečné heslo, ktoré si ľahko zapamätáte',
        en: 'create your own unique password that you can easily remember',
        hu: 'hozzon létre saját egyedi jelszót, amelyet könnyen megjegyezhet',
        ro: 'creați o parolă unică pe care să o puteți reține cu ușurință',
    },
    repeatPasswordDesc: {
        pl: 'aby upewnić się, że pamiętasz hasło, wpisz je jeszcze raz',
        cs: 'abyste se ujistili, že si heslo pamatujete, zadejte ho znovu',
        sk: 'aby ste sa uistili, že si zapamätáte svoje heslo, zadajte ho znova',
        en: 'to make sure you remember your password, type it again',
        hu: 'hogy biztosan emlékezzen a jelszavára, írja be újra',
        ro: 'pentru a vă asigura că vă amintiți parola, introduceți-o din nou',
    },
    loginPasswordDesc: {
        pl: 'Twoje unikalne hasło, stworzone podczas zakładania konta',
        cs: 'Vaše jedinečné heslo, které jste si vytvořili při vytváření účtu',
        sk: 'Vaše jedinečné heslo, ktoré ste si vytvorili pri vytváraní účtu',
        en: 'Your unique password that you created when you created account',
        hu: 'Az Ön egyedi jelszava, amelyet a fiók létrehozásakor hozott létre',
        ro: 'Parola unică pe care ați creat-o atunci când v-ați creat contul dvs.',
    },
    loginButtonText: {
        pl: 'Zaloguj się',
        cs: 'Přihlásit se',
        sk: 'Prihláste sa',
        en: 'Log in',
        hu: 'Belépés',
        ro: 'Conectați-vă',
    },
    logoutButtonText: {
        pl: 'Wyloguj się',
        cs: 'Odhlásit se',
        sk: 'Odhlásiť sa',
        en: 'Log out',
        hu: 'Kijelentkezés',
        ro: 'Deconectați-vă',
    },
    logoutMessage: {
        pl: 'Czy na pewno chcesz się wylogować?',
        cs: 'Opravdu se chcete odhlásit?',
        sk: 'Naozaj sa chcete odhlásiť?',
        en: 'Are you sure you want to log out?',
        hu: 'Biztosan ki akar jelentkezni?',
        ro: 'Sunteți sigur că doriți să vă deconectați?',
    },
    registerSlogan: {
        pl: 'Aby utworzyć konto i mieć dostęp do nauki wykupionego kursu językowego, wypełnij poniższe pola:',
        cs: 'Pro vytvoření účtu a přístup k zakoupenému jazykovému kurzu vyplňte níže uvedená pole:',
        sk: 'Ak chcete vytvoriť účet a získať prístup k štúdiu jazykového kurzu, ktorý ste si zakúpili, vyplňte polia nižšie:',
        en: 'Fill in the fields below to create an account and have access to your language course:',
        hu: 'Töltse ki az alábbi mezőket a fiók létrehozásához és a nyelvtanfolyamhoz való hozzáféréshez:',
        ro: 'Pentru a vă crea un cont și a avea acces pentru a studia cursul de limbă achiziționat, completați câmpurile de mai jos:',
    },
    loginSlogan: {
        pl: 'Jeśli posiadasz już konto, wypełnij poniższe pola:',
        cs: 'Pokud již máte účet, vyplňte níže uvedená pole:',
        sk: 'Ak už máte účet, vyplňte polia nižšie:',
        en: 'If you already have an account, please fill out the fields below:',
        hu: 'Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:',
        ro: 'Dacă aveți deja un cont, completați câmpurile de mai jos:',
    },
    registerButtonText: {
        pl: 'Załóż konto',
        cs: 'Vytvořit účet',
        sk: 'Vytvoriť účet',
        en: 'Create an account',
        hu: 'Fiók létrehozása',
        ro: 'Creați un cont',
    },
    levelOneText: {
        pl: 'podstawowy',
        cs: 'základní',
        sk: 'ZÁKLADNÉ',
        en: 'Basic',
        hu: 'Alapvető',
        ro: 'de bază',
    },
    levelTwoText: {
        pl: 'średni',
        cs: 'střední',
        sk: 'STREDNE',
        en: 'Medium',
        hu: 'Közepes',
        ro: 'intermediar',
    },
    levelThreeText: {
        pl: 'zaawansowany',
        cs: 'pokročilý',
        sk: 'POKROČILÉ',
        en: 'Advanced',
        hu: 'Fejlett',
        ro: 'avansat',
    },
    pageTitle: {
        pl: 'polyglot',
        cs: 'polyglot',
        sk: 'polyglot',
        en: 'polyglot',
        hu: 'polyglot',
        ro: 'polyglot',
    },
    activityExpressionLearning: {
        pl: 'Poznaj słowo',
        cs: 'Zkoumej slovo',
        sk: 'Objavte slovo',
        en: 'Discover the word',
        hu: 'Fedezze fel a szót',
        ro: 'Descoperiți cuvântul',
    },
    activityExpressionListening: {
        pl: 'Co słyszysz?',
        cs: 'Vyber, co slyšíš',
        sk: 'Vyberte, čo počujete',
        en: 'Mark what you hear',
        hu: 'Jelölje meg, mit hall',
        ro: 'Marcați ceea ce auziți',
    },
    activityExpressionTranslation: {
        pl: 'Ułóż zdanie',
        cs: 'Použijte slova a správně přeložte',
        sk: 'Použite slová na vytvorenie správneho prekladu',
        en: 'Make the correct translation from the words',
        hu: 'Készítsd el a helyes fordítást a szavakból!',
        ro: 'Faceți traducerea corectă din cuvinte',
    },
    activityUnscrambleExpressionLetters: {
        pl: 'Ułóż słowo',
        cs: 'Použijte písmena a správně přeložte',
        sk: 'Použite písmená na vytvorenie správneho prekladu',
        en: 'Use letters to make the correct translation',
        hu: 'Használd a betűket a helyes fordítás elkészítéséhez',
        ro: 'Folosiți literele pentru a face traducerea corectă',
    },
    activityLinkExpressions: {
        pl: 'Połącz w pary',
        cs: 'Párová slova',
        sk: 'Vytvorte dvojice slov',
        en: 'Put them together',
        hu: 'Tedd össze őket',
        ro: 'Puneți-le împreună',
    },
    exampleSentence: {
        pl: 'Przykładowe zdanie',
        cs: 'Příklad věty',
        sk: 'Príklad vety',
        en: 'Example sentence',
        hu: 'Példamondat',
        ro: 'Exemplu de propoziție',
    },
    yourActivity: {
        pl: 'Twoja aktywność',
        cs: 'Tvoje aktivita',
        sk: 'Vaša aktivita',
        en: 'Your activity',
        hu: 'Az Ön tevékenysége',
        ro: 'Activitatea ta',
    },
    tutorial: {
        pl: 'Samouczek',
        cs: 'Výukový kurz',
        sk: 'Učebnica',
        en: 'Tutorial',
        hu: 'Oktatóanyag',
        ro: 'Tutorial',
    },
    changePassword: {
        pl: 'Zmień hasło',
        cs: 'Změna hesla',
        sk: 'Zmena hesla',
        en: 'Change Password',
        hu: 'Jelszó megváltoztatása',
        ro: 'Schimbați parola',
    },
    logOut: {
        pl: 'Wyloguj się',
        cs: 'Odhlásit se',
        sk: 'Odhlásiť sa',
        en: 'Log out',
        hu: 'Kijelentkezés',
        ro: 'Deconectați-vă',
    },
    help: {
        pl: 'Pomoc',
        cs: 'Nápověda',
        sk: 'Pomoc',
        en: 'Help',
        hu: 'Segítség',
        ro: 'Ajutor',
    },
    goodAnswer: {
        pl: 'Dobrze',
        cs: 'Dobře',
        sk: 'Dobrý',
        en: 'Good',
        hu: 'Jó',
        ro: 'Bun',
    },
    badAnswer: {
        pl: 'Źle',
        cs: 'Špatně',
        sk: 'Zlé',
        en: 'Bad',
        hu: 'Rossz',
        ro: 'Rău',
    },
    continueTasks: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Pokračovať',
        en: 'Continue',
        hu: 'Folytatás',
        ro: 'Continuați',
    },
    lessonCompleted: {
        pl: 'ukończone',
        cs: 'Dokončeno',
        sk: 'Dokončené',
        en: 'Completed',
        hu: 'Befejezett',
        ro: 'Completat',
    },
    lessonInProgress: {
        pl: 'w trakcie',
        cs: 'Probíhá',
        sk: 'prebieha',
        en: 'in progress',
        hu: 'folyamatban',
        ro: 'în timp ce',
    },
    lessonNotActive: {
        pl: 'niekatywne',
        cs: 'Není aktivní',
        sk: 'nie je aktívny',
        en: 'not active',
        hu: 'nem aktív',
        ro: 'neactiv',
    },
    minutes: {
        pl: 'minut',
        cs: 'minut',
        sk: 'minút',
        en: 'minutes',
        hu: 'perc',
        ro: 'minute',
    },
    languageChangeYourCourses: {
        pl: 'Twoje kursy',
        cs: 'Vaše kurzy',
        sk: 'Vaše kurzy',
        en: 'Your courses',
        hu: 'A tanfolyamaid',
        ro: 'Cursurile dumneavoastră',
    },
    languageChangeAvailable: {
        pl: 'Rozszerz swoje umiejętności językowe:',
        cs: 'Rozšiř své jazykové dovednosti:',
        sk: 'Rozšír svoje jazykové schopnosti:',
        en: 'Also available:',
        hu: 'Fejleszd nyelvi készségeidet:',
        ro: 'Îmbunătățește-țiabilitățile lingvistice:',
    },
    languageActivationInputLabel: {
        pl: 'Wprowadź kod, aby aktywować nowy język:',
        cs: 'Zadejte kód pro aktivaci nového jazyka:',
        sk: 'Zadaním kódu aktivujete nový jazyk:',
        en: 'Enter the code to activate the new language:',
        hu: 'Írd be a kódot az új nyelv aktiválásához:',
        ro: 'Introduceți codul pentru a activa noua limbă:',
    },
    languageActivationButtonText: {
        pl: 'aktywuj',
        cs: 'aktivace',
        sk: 'aktivácia',
        en: 'activation',
        hu: 'aktiválás',
        ro: 'activați',
    },
    languageActivationInfoFirst: {
        pl: 'Nie masz kodu?',
        cs: 'Nemáte kód?',
        sk: 'Nemáte kód?',
        en: "Don't have a code?",
        hu: 'Nincs kódod?',
        ro: 'Nu aveți un cod?',
    },
    languageActivationInfoSecond: {
        pl: 'Napisz do nas na maila: kontakt@polyglot-edu.com',
        cs: 'Napište nám na adresu: kzs@polyglot-edu.com',
        sk: 'Napíšte nám na: ksz@polyglot-edu.com',
        en: 'Write to us at:',
        hu: 'Írj nekünk:',
        ro: 'Scrieți-ne pe e-mail: bsc@polyglot.eu',
    },
    contactText: {
        pl: 'W razie problemów prosimy o kontakt:',
        cs: 'V případě jakýchkoli problémů se prosím obraťte na:',
        sk: 'V prípade akýchkoľvek problémov nás prosím kontaktujte:',
        en: 'If you have any problems please contact us:',
        hu: 'Ha bármilyen problémája van, forduljon hozzánk:',
        ro: 'Dacă aveți probleme, vă rugăm să ne contactați:',
    },
    contactEmail: {
        pl: 'ksz@polyglot-edu.com',
        cs: 'kzs@polyglot-edu.com',
        sk: 'ksz@polyglot-edu.com',
        en: 'ksz@polyglot-edu.com',
        hu: 'ksz@polyglot-edu.com',
        ro: 'bsc@polyglot.eu',
    },
    certButtonText: {
        pl: 'Certyfikat',
        cs: 'Certifikát',
        sk: 'Certifikát',
        en: 'Certificate',
        hu: 'Certificate',
        ro: 'Certificat',
    },
    certText: {
        pl: 'Ukończyłeś cały kurs i chcesz uzyskać certyfikat? Napisz do nas:',
        cs: 'Absolvovali jste celý kurz a chcete získat certifikát? Napište nám na adresu:',
        sk: 'Absolvovali ste celý kurz a chcete získať certifikát? Napíšte nám na:',
        en: 'Have you completed the entire course and want to get a certificate? Write to us at:',
        hu: 'Végigcsináltad a teljes tanfolyamot és szeretnél egy tanúsítványt? Írj nekünk:',
        ro: 'Ați finalizat întregul curs și doriți să obțineți un certificat? Scrieți-ne la:',
    },
    certEmail: {
        pl: 'bok@polyglot-edu.com',
        cs: 'kzs@polyglot-edu.com',
        sk: 'ksz@polyglot-edu.com',
        en: 'csc@polyglot-edu.com',
        hu: 'csc@polyglot-edu.com',
        ro: 'bsc@polyglot.eu',
    },
    properAnswer: {
        pl: 'Poprawna odpowiedź:',
        cs: 'Správná odpověď:',
        sk: 'Správna odpoveď:',
        en: 'Correct answer:',
        hu: 'Helyes válasz:',
        ro: 'Răspuns corect:',
    },
    lessonModalHeader: {
        pl: 'Świetnie!',
        cs: 'Skvělé!',
        sk: 'Skvelé!',
        en: 'Great!',
        hu: 'Nagyszerű!',
        ro: 'Foarte bine!',
    },
    lessonModalText: {
        pl: 'Koniec lekcji',
        cs: 'Lekci jste dokončili',
        sk: 'Ukončili ste lekciu',
        en: 'You have completed the lesson',
        hu: 'Befejezted a leckét',
        ro: 'Ați finalizat lecția',
    },
    lessonModalNextLessonText: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Ďalej',
        en: 'Next',
        hu: 'Következő',
        ro: 'Următorul',
    },
    lessonModalToAllLessonText: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'Összes lecke',
        ro: 'Toate lecțiile',
    },
    tutorialFirstPointText: {
        pl: 'Ucz się, klikając w duży przycisk "Kontynuuj naukę". To takie proste!',
        cs: 'Učení vždy začněte kliknutím na velké tlačítko "Pokracovat v uceni". Je to tak jednoduché!',
        sk: 'Učenie vždy začnite kliknutím na veľké tlačidlo "Pokračovať v učení". Je to tak jednoduché!',
        en: 'Always start learning by clicking on the big "Pokracovat v uceni" button. It\'s that simple!',
        hu: 'A tanulást mindig a „Pokracovat v uceni” nagy gombra kattintva kezd el. Ennyire egyszerű!',
        ro: 'Întotdeauna începe să înveți apasând pe butonul mare „Continuați să învățați  ”. Acest lucru e atât de simplu!',
    },
    tutorialSecondPointText: {
        pl: 'Chcesz zapoznać się z innymi lekcjami? Po klinknięciu w przycisk "Wszystkie lekcje" zapoznasz się ze szczegółowym planem.',
        cs: 'Chcete se seznámit s dalšími lekcemi? Po kliknutí na "Vsechny lekce" si můžete prohlédnout podrobný jízdní řád.',
        sk: 'Chcete sa naučiť ďalšie lekcie? Kliknutím na "Všetky lekcie" zobrazíte podrobný rozvrh.',
        en: 'Do you want to get to know other lessons? After clicking on "Vsechny lekce" you can see the detailed schedule.',
        hu: 'Szeretnél megismerkedni más leckékkel? A „Vsechny lekce” gombra kattintva megtekintheted a részletes menetrendet.',
        ro: 'Dorești să cunoști alte lecții? După ce ai apăsat pe buton „Toate lecțiile ” poți vedea planul detaliat.',
    },
    tutorialThirdPointText: {
        pl: 'W tym miejscu poznasz dokładny plan lekcji. Jeśli chcesz, możesz tu zmienić poziom nauki, a także zapoznać się z dodatkowymi materiałami.',
        cs: 'Zde najdete přesný jízdní řád. Pokud si přejete, můžete zde změnit úroveň lekcí a také si prohlédnout další materiály.',
        sk: 'Tu nájdete presný rozvrh. Ak chcete, môžete si tu zmeniť úroveň lekcií a prezrieť si ďalšie materiály.',
        en: 'Here you will find the exact schedule of lessons. If you wish, you can change the level of lessons here, and also view additional materials.',
        hu: 'Itt találod a leckék pontos menetrendjét. Ha szeretnéd, itt megváltoztathatod a leckék szintjét, és megtekintheted a további anyagokat.',
        ro: 'În acest loc vei afla orarul exact lecțiilor. Dacă dorești, poți modifica aici nivelul de învățământ, totodată vei putea observa materiale suplimentare.',
    },
    tutorialFourthPointText: {
        pl: 'Chcesz zmienić poziom nauki? Zadania są dla Ciebie zbyt proste, albo zbyt trudne? Wystarczy, że klikniesz w wybrany przez siebie poziom w tym miejscu.',
        cs: 'Chcete změnit úroveň učení? Jsou pro vás úkoly příliš snadné nebo příliš obtížné? Stačí kliknout na vybranou úroveň.',
        sk: 'Chcete zmeniť úroveň vzdelávania? Sú pre vás úlohy príliš ľahké alebo príliš ťažké? Stačí kliknúť na vybranú úroveň.',
        en: 'Do you want to change the level of learning? Are the tasks too easy or too difficult for you? Just click on the desired level here.',
        hu: 'Szeretnéd megváltoztatni a leckék szintjét? A feladatok túl könnyűek vagy túl nehézek számodra? Csak kattints a kívánt szintre.',
        ro: 'Ai dori să schimbi nivelul de învățământ? Sarcinile sunt prea simple sau prea dificile pentru tine? Trebuie numai să apesi butonul pe nivelul dorit în acest loc.',
    },
    tutorialFifthPointText: {
        pl: 'Okno Twojej aktywności pokazuje, jak przebiega Twój proces nauki. Systematyczna nauka sprawi że osiągniesz wymarzone rezultaty jeszcze szybciej.',
        cs: 'Okno s aktivitami vám ukazuje, jak probíhá vaše učení. Pokud budete studovat systematicky, dosáhnete vysněných výsledků ještě rychleji.',
        sk: 'V okne aktivít sa zobrazuje priebeh učenia. Ak budete študovať systematicky, dosiahnete svoje vysnívané výsledky ešte rýchlejšie.',
        en: 'Your activity window shows how your learning process is going. Systematic learning will make you achieve your dream results even faster.',
        hu: 'Az aktivitásablak mutatja, hogyan halad a tanulási folyamatod. A rendszeres tanulásnak köszönhetően még gyorsabban elérheted a kívánt eredményeket.',
        ro: 'Fereastra ta de activitate arată cum decurge procesul tău de învățământ. Cu ajutorul învăţării sistematice vei obține rezultatele visate cât mai repede.',
    },
    tutorialSixthPointText: {
        pl: 'W tym miejscu widzisz, jakiego języka aktualnie się uczysz. Jeśli posiadasz dostęp do innych kursów, po kliknięciu w “Aktywuj kolejny kurs >>” możesz wybrać inny język do nauki.',
        cs: 'Zde se můžete podívat, který jazyk se právě učíte. Pokud máte přístup k dalším kurzům, můžete si vybrat další jazyk, který se chcete naučit, kliknutím na "další kurzy >>".',
        sk: 'Tu môžete vidieť, ktorý jazyk sa práve učíte. Ak máte prístup k iným kurzom, môžete si vybrať ďalší jazyk, ktorý sa chcete naučiť, kliknutím na "ďalšie kurzy >>".',
        en: 'Here you can see what language you are currently learning. If you have access to other courses, you can choose another language to learn by clicking on "other courses >>".',
        hu: 'Itt láthatod, milyen nyelvet tanulsz éppen. Ha hozzáférésed van más kurzusokhoz, akkor a „további kurzusok >>” gombra kattintva választhatsz másik nyelvet, amit szeretnél megtanulni.',
        ro: 'În acest loc vezi ce limbă înveți în prezent. Dacă ai acces la alte cursuri, după apăsarea pe butonul “ alte cursuri >>” poți alege altă limbă care ai să înveți.',
    },
    tutorialSeventhPointText: {
        pl: 'Chcesz nauczyć się kolejnego języka? Wpisz tutaj kod aktywacyjny nowego języka.',
        cs: 'Chcete se naučit další jazyk? Zde zadejte aktivační kód pro nový jazyk.',
        sk: 'Chcete sa naučiť ďalší jazyk? Tu zadajte aktivačný kód pre nový jazyk.',
        en: 'Do you want to learn another language? Enter the activation code for the new language here.',
        hu: 'Szeretnél megtanulni egy újabb nyelvet? Írd be ide az új nyelv aktiválókódját.',
        ro: 'Dorești să înveți mai o limbă? Introduce aici codul de activare pentru noua limbă.',
    },
    tutorialEighthPointText: {
        pl: 'Każdego dnia, dowiedz się czegoś nowego! Aby nauka nie stała się nudną rutyną, zebraliśmy dla Ciebie ciekawostki o krajach, których kursy są dostępne na polyglot.',
        cs: 'Každý den se naučte něco nového! Aby se učení nestalo nudnou rutinou, shromáždili jsme pro vás zajímavosti o zemích, jejichž kurzy jsou na polyglotu k dispozici.',
        sk: 'Každý deň sa naučte niečo nové! Aby sa učenie nestalo nudnou rutinou, zozbierali sme zaujímavé fakty o krajinách, ktorých kurzy sú dostupné na portáli polyglot.',
        en: 'Learn something new every day! To make learning not become a boring routine, we have collected interesting facts about the countries whose courses are available on polyglot.',
        hu: 'Minden nap tanulj meg valami újat! Hogy a tanulás ne váljon unalmas rutinná, összegyűjtöttünk neked néhány érdekességet azokról az országokról, amelyek nyelvtanfolyamai elérhetőek az polyglot.',
        ro: 'În fiecare zi, află ceva nou! Pentru ca învățământul să nu devină o rutină plictisitoare, am adunat pentru tine informații interesante despre țările ale căror cursuri sunt disponibile pe polyglot.',
    },
    tutorialNinthPointText: {
        pl: 'Po kliknięciu na symbol 3 poziomych kresek w prawym górnym rogu ekranu, rozwinie się panel użytkownika. Jest to miejsce, w którym uzyskasz dostęp do najważniejszych funkcji, takich jak zmiana hasła, czy wylogowanie. Możesz tu także sprawdzić swoją aktywność, albo uzyskać informacje o kontakcie lub certyfikacie.',
        cs: 'Pokud kliknete na symbol 3 vodorovných čárek v pravém horním rohu obrazovky, rozbalí se uživatelský panel. Zde máte přístup k nejdůležitějším funkcím, jako je změna hesla nebo odhlášení. Zde můžete také zkontrolovat svou aktivitu nebo získat kontaktní informace či certifikát.',
        sk: 'Ak kliknete na symbol 3 vodorovných čiar v pravom hornom rohu obrazovky, používateľský panel sa rozbalí. Tu máte prístup k najdôležitejším funkciám, ako je zmena hesla alebo odhlásenie. Tu si tiež môžete skontrolovať svoju aktivitu alebo získať svoje kontaktné informácie či certifikát.',
        en: 'After clicking on the button on the symbol of 3 horizontal lines in the upper right corner of the screen, the navigation panel will open. Here you will get access to the most important functions such as changing the password or logging out. You can also check your activity, or get contact information or a certificate.',
        hu: 'Ha rákattintasz a képernyő jobb felső sarkában található 3 vízszintes vonalra, akkor megnyílik a felhasználói panel. Itt éred el a legfontosabb funkciókat, mint például a jelszó megváltoztatása vagy a kijelentkezés. Itt ellenőrizheted a tevékenységedet, vagy megnézheted a kapcsolatfelvételi információkat vagy a tanúsítványt.',
        ro: 'După ce vei apăsa buton pe simbolul a 3 linii orizontale din colțul din dreapta sus al ecranului, se va deschide panoul de navigare. Aici vei obține acces la cele mai importante funcții precum schimbarea parolei sau deconectarea. Poți verifica activitatea personal, sau obține informații despre un contact sau un certificat.',
    },
    tutorialPointTitleLesson: {
        pl: 'Lekcja',
        cs: 'Lekce',
        sk: 'Lekcia',
        en: 'Lesson',
        hu: 'Lecke',
        ro: '',
    },
    tutorialLessonFirstPointText: {
        pl: 'Na samej górze widzisz poziom nauki, tytuł lekcji oraz jej numer',
        cs: 'Úplně nahoře vidíte úroveň učení, název lekce a číslo lekce.',
        sk: 'Úplne hore vidíte úroveň učenia, názov lekcie a číslo lekcie.',
        en: 'At the very top you can see the learning level, lesson title and lesson number',
        hu: 'Legfelül láthatja a tanulási szintet, a lecke címét és az óra számát',
        ro: 'Pe partea care este mai sus poți vedea nivelul de învățământ, titlul și numărul lecției',
    },
    tutorialLessonSecondPointText: {
        pl: 'Każde z zadań zawiera krótkie polecenie, dzięki któremu wiesz, co robić.',
        cs: 'Každý úkol obsahuje krátký příkaz, abyste věděli, co máte udělat.',
        sk: 'Každá úloha obsahuje krátky príkaz, aby ste vedeli, čo máte urobiť.',
        en: 'Each task has a short command so you know what to do.',
        hu: 'Minden feladathoz tartozik egy rövid parancs, így tudja, mit kell tennie.',
        ro: 'Fiecare sarcină are o scurtă comandă care  spune ce trebuie de făcut.',
    },
    tutorialLessonThirdPointText: {
        pl: 'Na samym dole znajduje się Twój pasek postępu. Zauważ, że każdy z jego kolorów oznacza coś innego:',
        cs: 'Úplně dole se nachází ukazatel průběhu. Všimněte si, že každá z jeho barev znamená něco jiného:',
        sk: 'Úplne dole sa nachádza ukazovateľ priebehu. Všimnite si, že každá z jeho farieb znamená niečo iné:',
        en: 'At the very bottom is your progress bar. Notice that each of its colors means something else:',
        hu: 'Lent láthatja a haladási sávját. Vegye észre, hogy mindegyik színe mást jelent:',
        ro: 'Pe partea care este mai jos se află bara ta de progres. Reține, că fiecare dintre culorile  înseamnă altceva:',
    },
    tutorialLessonNestedListFirstPointText: {
        pl: 'zielony - dobrze rozwiązane zadanie',
        cs: 'zelený - dobře vyřešený úkol',
        sk: 'zelený - dobre vyriešená úloha',
        en: 'green - well solved task',
        hu: 'zöld - jól megoldott feladat',
        ro: 'verde - o sarcină bine rezolvată',
    },
    tutorialLessonNestedListSecondPointText: {
        pl: 'czerwony - źle rozwiązane zadanie (wrócisz do niego pod koniec lekcji)',
        cs: 'červený - špatně vyřešená úloha (vrátíte se k ní na konci lekce).',
        sk: 'červený - zle vyriešená úloha (vrátite sa k nej na konci hodiny)',
        en: 'red - incorrectly solved task (you will return to it at the end of the lesson)',
        hu: 'piros - rosszul megoldott feladat (visszatér hozzá a lecke végén)',
        ro: 'roșu - o sarcină incorect rezolvată (vei reveni la ea la sfârșitul lecției)',
    },
    tutorialLessonNestedListThirdPointText: {
        pl: 'żółty - miejsce, w którym aktualnie się znajdujesz',
        cs: 'žlutý - kde se právě nacházíte',
        sk: 'žltý - kde sa práve nachádzate',
        en: 'yellow - where you are now',
        hu: 'sárga - hol tart most',
        ro: 'galben – unde te afli în acest moment',
    },
    tutorialTenthPointText: {
        pl: 'Na końcu każdego poziomu czeka na Ciebie test, który sprawdzi Twoją wiedzę z zakresu danego poziomu. Aby do niego przystąpić musisz mieć zaliczone wszystkie lekcje z tego poziomu.',
        cs: 'Na konci každé úrovně vás čeká test, který prověří vaše znalosti dané úrovně. Abyste to mohli začít, musíte absolvovat všechny lekce z této úrovně.',
        sk: 'Na konci každej úrovne vás čaká test, ktorý preverí vaše znalosti danej úrovne. Aby ste to mohli začať, musíte absolvovať všetky lekcie z tejto úrovne.e',
        en: 'At the end of each level, a test awaits you to test your knowledge of that level. To start it, you must have completed all the lessons from this level.',
        hu: 'Minden szint végén egy teszt vár Önre, amely ellenőrzi a tudását az adott szintről. Hogy hozzájuthasson, teljesítenie kell az összes leckét ezen a szinten.',
        ro: 'La sfârșitul fiecărui nivel, te așteaptă un test pentru a-ți testa cunoștințele despre acel nivel. Pentru a participa la el, trebuie să fi finalizat toate lecțiile de la acest nivel.',
    },
    testLessonScreenText: {
        pl: 'Sprawdź swoją wiedzę z poziomu',
        cs: 'Otestujte si své znalosti od úrovně',
        sk: 'Otestujte si svoje znalosti od úrovne',
        en: 'Test your knowledge from level',
        hu: 'Tesztelje tudását a szintről',
        ro: 'Testează-ți cunoștințele de la nivelu',
    },
    testLessonScreenButtonText: {
        pl: 'wypełnij test',
        cs: 'vyplňte test',
        sk: 'vyplňte test',
        en: 'fill out the test',
        hu: 'töltse ki a tesztet',
        ro: 'completează formularul',
    },
    testMainHeader: {
        pl: 'Test wiedzy - poziom',
        cs: 'Test znalostí - úroveň',
        sk: 'Vedomostný test - úroveň',
        en: 'Knowledge test - level',
        hu: 'Tudásteszt - szint',
        ro: 'Test de cunoștințe - nivel',
    },
    testRulesHeader: {
        pl: 'Zasady testu wiedzy',
        cs: 'Pravidla znalostního testu',
        sk: 'Pravidlá znalostného testu',
        en: 'Knowledge test rules',
        hu: 'A tudásteszt szabályai',
        ro: 'Hodně štěstí!',
    },
    testWelcome: {
        pl: 'Cześć',
        cs: 'Ahoj',
        sk: 'Ahoj',
        en: 'Hi',
        hu: 'Szia',
        ro: 'Bună',
    },
    testDesc: {
        pl: 'W tym miejscu możesz rozwiązać test wiedzy ze wszystkich poznanych słówek na poziomie {{level}}. Do każdego z zadań masz możliwość podejść tylko jeden raz. Pod koniec testu zostanie wyświetlony Twój wynik. Do testu możesz podchodzić wielokrotnie, by poprawić swój wynik.',
        cs: 'Zde můžete vyřešit znalostní test všech slov, která jste se naučili na úrovni {{level}}. Každý úkol můžete udělat pouze jednou. Na konci testu se zobrazí váš výsledek. Test můžete absolvovat vícekrát, abyste zlepšili své skóre.',
        sk: 'Tu môžete vyriešiť znalostný test všetkých slov, ktoré ste sa naučili na úrovni {{level}}. Každú úlohu môžete urobiť iba raz. Na konci testu sa zobrazí váš výsledok. Test môžete absolvovať viackrát, aby ste zlepšili svoje skóre.',
        en: 'Here you can solve the knowledge test of all the words you have learned at level {{level}}. You can do each task only once. At the end of the test, your result will be displayed. You can take the test multiple times to improve your score.',
        hu: 'Itt megoldhatja a tudástesztet az összes szóra, amelyet a {{level}} szinten tanult. Minden feladatot csak egyszer végezhet el. A teszt végén megjelenik az eredménye. A tesztet többször is elvégezheti, hogy javítsa a pontszámát.',
        ro: 'Aici puteți rezolva testul de cunoștințe al tuturor cuvintelor pe care le-ați învățat la nivelul {{level}}. Puteți face fiecare sarcină o singură dată. La sfârșitul testului, rezultatul dvs. va fi afișat. Puteți susține testul de mai multe ori pentru a vă îmbunătăți scorul.',
    },
    testGoodLuck: {
        pl: 'Powodzenia!',
        cs: 'Hodně štěstí!',
        sk: 'Veľa šťastia!',
        en: 'Good luck!',
        hu: 'Sok szerencsét!',
        ro: 'Mult noroc!',
    },
    testStartBtn: {
        pl: 'Wypełnij test',
        cs: 'Dokončit test',
        sk: 'Dokončite test',
        en: 'Complete the test',
        hu: 'Teszt kitöltése',
        ro: 'Finaliza testul',
    },
    testResultHeader: {
        pl: 'Wynik testu',
        cs: 'Výsledek testu',
        sk: 'Výsledok testu',
        en: 'Test result',
        hu: 'Teszteredmény',
        ro: 'Rezultatul testului',
    },
    testActivitiesCompleted: {
        pl: 'Ilość dobrze zrobionych zadań:',
        cs: 'Počet dobře provedených úkolů:',
        sk: 'Počet dobre vykonaných úloh:',
        en: 'Number of tasks done well:',
        hu: 'A feladatok száma:',
        ro: 'Număr de sarcini bine realizate:',
    },
    testRepeatDesc: {
        pl: 'Jeśli chcesz możesz podejść do testu jeszcze raz i poprawić swój wynik.',
        cs: 'Chcete-li, můžete test absolvovat znovu a zlepšit svůj výsledek.',
        sk: 'Ak chcete, môžete test absolvovať znova a zlepšiť svoj výsledok.',
        en: 'If you want, you can take the test again and improve your score.',
        hu: 'Ha szeretné, megismételheti a tesztet, és javíthatja az eredményét.',
        ro: 'Dacă doriți, puteți să faceți din nou testul și să vă îmbunătățiți rezultatul.',
    },
    testBestScore: {
        pl: 'Twój najlepszy wynik:',
        cs: 'Váš nejlepší výsledek:',
        sk: 'Váš najlepší výsledok:',
        en: 'Your best score:',
        hu: 'Legjobb eredménye:',
        ro: 'Cel mai bun rezultat al tau:',
    },
    testBackBtn: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'Összes lecke',
        ro: 'Toate lecțiile',
    },
    testRepeaBtn: {
        pl: 'Wypełnij test ponownie',
        cs: 'Udělat test znovu',
        sk: 'Urobte si test znova',
        en: 'Complete the test again',
        hu: 'Teszt újbóli kitöltése',
        ro: 'Face din nou testul',
    },
    askExpert: {
        pl: 'Zapytaj eksperta',
        cs: 'Zeptejte se odborníka',
        sk: 'Spýtajte sa odborníka',
        en: 'Ask the expert',
        hu: 'Kérdezze a szakértőt',
        ro: 'Întrebați-l pe expert',
    },
    askExpertDescription: {
        pl: 'Masz trudności z rozwiązaniem zadania? Zadaj pytanie naszemu ekspertowi. Odpowiedź pojawi się w skrzynce odbiorczej.',
        cs: 'Máte potíže s řešením úkolu? Položte otázku našemu odborníkovi. Odpověď se objeví ve vaší schránce.',
        sk: 'Máte ťažkosti s riešením úlohy? Položte otázku nášmu odborníkovi. Odpoveď sa objaví vo vašej schránke.',
        en: 'Having difficulty solving an assignment? Ask your question to our expert. The answer will appear in your inbox.',
        hu: 'Nehézséget okoz egy feladat megoldása? Tegye fel kérdését szakértőnknek. A válasz a postaládájába fog érkezni.',
        ro: 'Aveți dificultăți în rezolvarea unei sarcini? Adresați o întrebare expertului nostru. Răspunsul va apărea în căsuța dvs. poștală.',
    },
    askExpertSend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldje el',
        ro: 'Trimiteți',
    },
    askExpertInboxHeader: {
        pl: 'Pytanie do lekcji',
        cs: 'Otázka k lekci',
        sk: 'Otázka k lekcii',
        en: 'Question for the lesson',
        hu: 'Kérdés a leckéhez',
        ro: 'Întrebare pentru lecție',
    },
    askExpertAnswer: {
        pl: 'Odpowiedź eksperta',
        cs: 'Odpověď odborníka',
        sk: 'Odpoveď odborníka',
        en: 'Answer from the expert',
        hu: 'A szakértő válasza',
        ro: 'Răspuns de la expert',
    },
    inbox: {
        pl: 'Skrzynka odbiorcza',
        cs: 'Doručená pošta',
        sk: 'Doručená pošta',
        en: 'Inbox',
        hu: 'Bejövő postaláda',
    },
    makeRateText: {
        pl: 'Oceń kartę:',
        cs: 'Hodnotící karta:',
        sk: 'Hodnotiaca karta:',
        en: 'Rate card:',
        hu: 'Díjtáblázat:',
        ro: 'Carte de tarife:',
    },
    pollHeading: {
        pl: 'Kolejna lekcja za Tobą!',
        cs: 'Další lekce je za tebou!',
        sk: 'Ďalšia lekcia je za tebou!',
        en: 'Another lesson has been completed!',
        hu: 'Már túl vagy egy újabb leckén!',
        ro: 'O altălecție este în spatele tău!',
    },
    pollFirstParagraph: {
        pl: 'Chcemy poznać Twoją opinię, abyśmy mogli dalej rozwijać kurs i dopasowywać go do Twoich potrzeb. Prosimy o zaznaczenie odpowiedzi.',
        cs: 'Chceme znát tvůj názor, abychom mohli kurz dále rozvíjet a přizpůsobovat ho tvým potřebám. Prosíme o označeníodpovědi.',
        sk: 'Chceme poznať tvoj názor, aby sme mohli ďalejrozvíjať kurz a prispôsobovať ho tvojim potrebám. Prosíme o označenie odpovede.',
        en: 'We are eager to hear your feedback, which enables us to continue refining and tailoring our course to meet your needs. Please, indicate your responses.',
        hu: 'Szeretnénk megismerni a véleményed, hogy tovább fejleszthessük a tanfolyamot és igazíthassuk a te igényeidhez. Kérjük, jelöld be a válaszod.',
        ro: 'Vrem săștimpărerea ta, pentru a putea dezvolta în continuare cursul și a-l adapta nevoilor tale. Te rugămsă marchezi răspunsul.',
    },
    pollSecondParagraph: {
        pl: 'W skali od 1 do 5, gdzie 1 to całkowicie niezadowolony, a 5 to bardzo zadowolony, jak oceniasz nasząaplikację pod kątem:',
        cs: 'Na stupnici od 1 do 5, kde 1 je zcela nespokojený a 5 je velmi spokojený, jak hodnotíš naši aplikaci z hlediska:',
        sk: 'Na stupnici od 1 do 5, kde 1 je úplne nespokojný a 5 je veľmi spokojný, ako hodnotíš našu aplikáciu z hľadiska:',
        en: 'On a scale from 1 to 5, where 1 represents complete dissatisfaction and 5 signifies great satisfaction, how would you rate our application in terms of:',
        hu: '1-től 5-ig terjedő skálán, ahol 1 teljesen elégedetlen, 5 pedig nagyon elégedett, hogyan értékelnéd az alkalmazásunkat a következő szempontok alapján:',
        ro: 'Pe o scară de la 1 la 5, unde 1 este complet nemulțumitși 5 este foarte mulțumit, cum evaluezi aplicațianoastră în ceea ce privește:',
    },
    pollFirstQuestion: {
        pl: 'jakości treści edukacyjnych',
        cs: 'Kvality vzdělávacíchobsahů',
        sk: 'Kvality vzdelávacích obsahov',
        en: 'The quality of educational content',
        hu: 'Az oktatási tartalom minősége',
        ro: 'Calitatea conținutuluieducațional',
    },
    pollSecondQuestion: {
        pl: 'jakości audio i zdjęć',
        cs: 'Kvality audia a fotografií',
        sk: 'Kvality audia a fotografií',
        en: 'The quality of audio and visuals',
        hu: 'Az audio és a képek minősége',
        ro: 'Calitatea audio și a fotografiilor',
    },
    pollThirdQuestion: {
        pl: 'łatwości korzystania z aplikacji',
        cs: 'Jednoduchosti používání aplikace',
        sk: 'Lahkosti používania aplikácie',
        en: 'The user-friendliness of the application',
        hu: 'Az alkalmazás könnyű használata',
        ro: 'Ușurința de utilizare a aplicației',
    },
    pollSecondHeading: {
        pl: 'Ostatnie pytanie:',
        cs: 'Poslední otázka',
        sk: 'Posledná otázka',
        en: 'Last question',
        hu: 'Utolsó kérdés',
        ro: 'Ultima întrebare',
    },
    pollFourthQuestion: {
        pl: 'Jakie zmiany w naszej platformie chcesz zobaczyć w przyszłości?',
        cs: 'Jaké změny na naší platformě chceš vidět v budoucnosti?',
        sk: 'Aké zmeny na našej platforme chceš vidieť v budúcnosti?',
        en: 'What improvements would you like to see in our platform in the future?',
        hu: 'Milyen változásokat szeretnél látni a platformunkon a jövőben?',
        ro: 'Ce schimbări în platforma noastrăți-arplăceasă vezi în viitor?',
    },
    pollTextareaPlaceholder: {
        pl: 'Kliknij w okienko, aby rozpocząć pisanie',
        cs: 'Klepnutím na pole začněte psát',
        sk: 'Kliknutím na políčko začnite písať',
        en: 'Click on the box to start typing',
        hu: 'Kattintson a mezőre a gépelés megkezdéséhez',
        ro: 'Faceți clic pe casetă pentru a începe să tastați',
    },
    pollThanksParagraph: {
        pl: 'Dziękujemy za wypełnienie ankiety!',
        cs: 'Děkujeme za vyplnění dotazníku!',
        sk: 'Ďakujeme za vyplnenie dotazníka!',
        en: 'Thank you for taking the time to complete our survey!',
        hu: 'Köszönjük, hogy kitöltötted a felmérést!',
        ro: 'Vămulțumim pentru completarea sondajului!',
    },
    pollThanksSecondParagraph: {
        pl: 'Twoja opinia jest dla nas bardzo cenna. Pomoże w dalszym rozwoju kursu.',
        cs: 'Tvůj názor je pro nás velmi cenný pro další rozvoj kurzu.',
        sk: 'Tvoj názor je pre nás veľmi cenný pri ďalšom rozvoji kurzu.',
        en: 'Your feedback is incredibly valuable to us as we continue to enhance our course.',
        hu: 'A véleményed nagyon értékes számunkra a tanfolyam továbbfejlesztésében.',
        ro: 'Părerea ta este foarte valoroasă pentru noi în dezvoltarea ulterioară a cursului.',
    },
    pollCancelButton: {
        pl: 'Zapytaj później',
        cs: 'Zeptejte se později',
        sk: 'Opýtajte sa neskôr',
        en: 'Ask later',
        hu: 'Kérdezz később',
        ro: 'Intreaba mai tarziu',
    },
    pollNextButton: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Ďalšie',
        en: 'Next',
        hu: 'Következő',
        ro: 'Următorul',
    },
    pollSendButton: {
        pl: 'Wyślij',
        cs: 'Poslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küld',
        ro: 'Trimite',
    },
    passwordRecoveryLoginText: {
        pl: 'Nie pamiętam hasła?',
        cs: 'Nepamatuji si heslo?',
        sk: 'Nepamätám si heslo?',
        en: "I don't remember the password?",
        hu: 'Nem emlékszem a jelszóra?',
        ro: 'Nu-mi amintesc parola?',
    },
    passwordRecoveryLoginLink: {
        pl: 'Kliknij tutaj',
        cs: 'Klikněte zde',
        sk: 'Kliknite sem',
        en: 'Click here',
        hu: 'Kattintson ide',
        ro: 'Faceți clic aici',
    },
    passwordRecoveryHeader: {
        pl: 'Nie pamiętam hasła',
        cs: 'Nepamatuji si heslo',
        sk: 'Nepamätám si heslo',
        en: "I don't remember the password",
        hu: 'Nem emlékszem a jelszóra',
        ro: 'Nu-mi amintesc parola',
    },
    passwordRecoveryDescription: {
        pl: 'Aby otrzymać link do zresetowania hasła, wprowadź adres e-mail powiązany z Twoim kontem.',
        cs: 'Abyste obdrželi odkaz na resetování hesla, zadejte níže e-mailovou adresu spojenou s vaším účtem.',
        sk: 'Ak chcete dostať odkaz na obnovenie hesla, zadajte nižšie e-mailovú adresu priradenú k vášmu kontu.',
        en: 'To receive a link to reset your password, enter the email address associated with your account below.',
        hu: 'Ha szeretne egy linket kapni jelszava visszaállításához, adja meg a fiókjához tartozó e-mail címet az alábbiakban.',
        ro: 'Pentru a primi un link de resetare a parolei, introduceți adresa de e-mail asociată contului dumneavoastră mai jos.',
    },
    passwordRecoveryEmail: {
        pl: 'Twój adres e-mail',
        cs: 'Váš e-mail',
        sk: 'Váš e-mail',
        en: 'Your e-mail',
        hu: 'Az Ön e-mail címe',
        ro: 'E-mail-ul dvs',
    },
    passwordRecoveryButton: {
        pl: 'Resetuj hasło',
        cs: 'Resetovat heslo',
        sk: 'Obnovenie hesla',
        en: 'Reset password',
        hu: 'Jelszó visszaállítása',
        ro: 'Resetare parolă',
    },
    passwordRecoveryButtonAfterSent: {
        pl: 'Wiadomość e-mail została wysłana',
        cs: 'E-mail byl odeslán',
        sk: 'E-mail bol odoslaný',
        en: 'The email has been sent',
        hu: 'Az e-mail elküldésre került',
        ro: 'E-mailul a fost trimis',
    },
    changePasswordHeader: {
        pl: 'Wprowadź nowe hasło',
        cs: 'Zadejte nové heslo',
        sk: 'Zadajte nové heslo',
        en: 'Enter a new password',
        hu: 'Új jelszó megadása',
        ro: 'Introduceți o nouă parolă',
    },
    changePasswordDescription: {
        pl: 'Wprowadź nowe hasło tutaj',
        cs: 'Zde zadejte své nové heslo',
        sk: 'Zadajte svoje nové heslo tu',
        en: 'Enter your new password here',
        hu: 'Adja meg új jelszavát itt',
        ro: 'Introduceți noua dvs. parolă aici',
    },
    changePasswordPasswordLabel: {
        pl: 'Nowe hasło',
        cs: 'Nové heslo',
        sk: 'Nové heslo',
        en: 'New password',
        hu: 'Új jelszó',
        ro: 'Parolă nouă',
    },
    changePasswordRepeatPasswordLabel: {
        pl: 'Potwierdź hasło',
        cs: 'Potvrďte heslo',
        sk: 'Potvrdenie hesla',
        en: 'Confirm password',
        hu: 'Jelszó megerősítése',
        ro: 'Confirmați parola',
    },
    changePasswordButton: {
        pl: 'Resetowanie hasła',
        cs: 'Resetovat heslo',
        sk: 'Obnovenie hesla',
        en: 'Reset password',
        hu: 'Jelszó visszaállítása',
        ro: 'Resetare parolă',
    },
    certificateHeader: {
        pl: 'CERTYFIKAT',
        cs: 'CERTIFIKÁT',
        sk: 'CERTIFIKÁT',
        en: 'CERTIFICATE',
        hu: 'TANÚSÍTVÁNY',
    },
    certificateNotAllowedHeader: {
        pl: 'UKOŃCZ WSZYSTKIE LEKCJE, ABY OTRZYMAĆ CERTYFIKAT',
        cs: 'DOKONČI VŠECHNY LEKCE, ABYS OBDRŽEL CERTIFIKÁT',
        sk: 'DOKONČI VŠETKY LEKCIE, ABY SI ZÍSKAL CERTIFIKÁT',
        en: 'COMPLETE ALL LESSONS TO GET A CERTIFICATE',
        hu: 'TÖLTSD KI AZ ÖSSZES LECKÉT, HOGY MEGKAPD A TANÚSÍTVÁNYT',
    },
    certificateDescription: {
        pl: 'Aby otrzymać certyfikat, wpisz swoje imię i nazwisko',
        cs: 'Chceš-li získat certifikát, zadej své jméno a příjmení',
        sk: 'Ak chceš získať certifikát, zadaj svoje meno a priezvisko',
        en: 'To receive a certificate, enter your name and surname',
        hu: 'Hogy megkapd a tanúsítványt, add meg a neved és a vezetékneved',
    },
    certificateName: {
        pl: 'Imię i nazwisko',
        cs: 'Jméno a příjmení',
        sk: 'Meno a priezvisko',
        en: 'Name and surname',
        hu: 'Név és vezetéknév',
    },
    certificateSend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldés',
    },
    certificateWarning: {
        pl: 'Upewnij się, że wpisujesz prawidłowe dane!',
        cs: 'Ujisti se, že zadáváš správné údaje. Není možnost pozdějšízměny.',
        sk: 'Uistite sa, že zadávate správne údaje. Neskôr nie je možné ich zmeniť.',
        en: 'Make sure you enter the correct data. There is no possibility of later change.',
        hu: 'Győződj meg róla, hogy helyes adatokat adtál meg. Később nem lehetséges módosítani.',
    },
    certificateSendInfo: {
        pl: 'Certyfikat zostanie wysłany do 3 dni roboczych na twój adres mailowy. Pobierzesz go też w tym miejscu.',
        cs: 'Certifikát bude odeslán do 3 pracovních dnů na tvou emailovou adresu. Můžeš si ho také stáhnout na tomto místě.',
        sk: 'Certifikát bude odoslaný do 3 pracovných dní na tvoju emailovú adresu. Môžeš ho stiahnuť aj na tomto mieste.',
        en: 'The certificate will be sent within 3 working days to your email address. You can also download it here.',
        hu: 'A tanúsítványt 3 munkanapon belül elküldjük az e-mail címedre. Letöltheted innen is.',
    },
    certificateDownload: {
        pl: 'Pobierz',
        cs: 'Stáhnout',
        sk: 'Stiahnuť',
        en: 'Download',
        hu: 'Letöltés',
    },
    task: {
        pl: 'Zadanie',
        cs: 'Úkol',
        sk: 'Úloha',
        en: 'Task',
        hu: 'Feladat',
    },
    taskActivityTitle: {
        pl: 'Sprawdźmy Twoją wiedzę z ostatnich lekcji.',
        cs: 'Zkontrolujme si tvé znalosti z posledních lekcí.',
        sk: 'Skontrolujme si tvoje znalosti z posledných lekcií.',
        en: "Let's check your knowledge from the last lessons.",
        hu: 'Nézzük át a legutóbbi leckékben tanultakat.',
    },
    taskActivityDescription: {
        pl: 'Nasz ekspert językowy sprawdzi twoją znajomość języka i wskaże, co możesz jeszcze poprawić.',
        cs: 'Náš jazykový expert zkontroluje tvé znalosti a ukáže ti, co můžeš ještě zlepšit.',
        sk: 'Náš jazykový expert skontroluje tvoje znalosti a ukáže ti, čo môžeš ešte zlepšiť.',
        en: 'Our language expert will check your knowledge and point out what you can improve.',
        hu: 'Nyelvi szakértőnk átnézi a tudásod és megmutatja, mit tudsz még fejleszteni.',
    },
    taskActivitySecondDescription: {
        pl: 'Wiadomość zwrotna pojawi się w skrzynce odbiorczej.',
        cs: 'Zpětná vazba se objeví v tvé schránce.',
        sk: 'Spätná väzba sa objaví v tvojej schránke.',
        en: 'The feedback will appear in your inbox.',
        hu: 'A visszajelzés megjelenik a beérkezett üzeneteid között.',
    },
    taskActivityName: {
        pl: 'Zadanie:',
        cs: 'Úkol:',
        sk: 'Úloha:',
        en: 'Task:',
        hu: 'Feladat:',
    },
    taskActivityDone: {
        pl: 'Zrobione',
        cs: 'Hotovo',
        sk: 'Hotovo',
        en: 'Done',
        hu: 'Kész',
    },
    taskActivityExample: {
        pl: 'Przykładowa odpowiedź',
        cs: 'Příklad odpovědi',
        sk: 'Príklad odpovede',
        en: 'Example answer',
        hu: 'Példaválasz',
    },
    taskActivitySkip: {
        pl: 'Pomiń',
        cs: 'Přeskočit',
        sk: 'Preskočiť',
        en: 'Skip',
        hu: 'Kihagy',
    },
    taskActivitySend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldés',
    },
    taskActivityMessageSent: {
        pl: 'Wiadomość pomyślnie wysłano',
        cs: 'Zpráva byla úspěšně odeslána',
        sk: 'Správa bola úspešne odoslaná',
        en: 'Message sent successfully',
        hu: 'Az üzenet sikeresen elküldve',
    },
    taskActivityMessageSentDescription: {
        pl: 'Odpowiedź pojawi się w skrzynce odbiorczej.',
        cs: 'Odpověď se objeví v tvé schránce.',
        sk: 'Odpoveď sa objaví v tvojej schránke.',
        en: 'The answer will appear in your inbox.',
        hu: 'A válasz megjelenik a beérkezett üzeneteid között.',
    },
    expert: {
        pl: 'Ekspert',
        cs: 'Expert',
        sk: 'Expert',
        en: 'Expert',
        hu: 'Szakértő',
    },
    descriptiveTask: {
        pl: 'Zadanie opisowe',
        cs: 'Popisný úkol',
        sk: 'Popisná úloha',
        en: 'Descriptive task',
        hu: 'Leíró feladat',
    },
    inboxHeader: {
        pl: 'Skrzynka odbiorcza',
        cs: 'Doručená pošta',
        sk: 'Doručená pošta',
        en: 'Inbox',
        hu: 'Beérkezett üzenetek',
    },
    inboxSender: {
        pl: 'Nadawca',
        cs: 'Odesílatel',
        sk: 'Odosielateľ',
        en: 'Sender',
        hu: 'Küldő',
    },
    inboxSubject: {
        pl: 'Temat',
        cs: 'Předmět',
        sk: 'Predmet',
        en: 'Subject',
        hu: 'Tárgy',
    },
    inboxDate: {
        pl: 'Data',
        cs: 'Datum',
        sk: 'Dátum',
        en: 'Date',
        hu: 'Dátum',
    },
    inboxResponse: {
        pl: 'Odpowiedź',
        cs: 'Odpověď',
        sk: 'Odpoveď',
        en: 'Response',
        hu: 'Válasz',
    },
    inboxTypeMessage: {
        pl: 'wpisz wiadomość...',
        cs: 'napiš zprávu...',
        sk: 'napíš správu...',
        en: 'type message...',
        hu: 'írd be az üzenetet...',
    },
    inboxSend: {
        pl: 'Wyślij',
        cs: 'Odeslat',
        sk: 'Odoslať',
        en: 'Send',
        hu: 'Küldés',
    },
    inboxAskExpert: {
        pl: 'Zapyaj eksperta',
        cs: 'Zeptej se experta',
        sk: 'Opýtaj sa experta',
        en: 'Ask expert',
        hu: 'Kérdezd meg a szakértőt',
    },
    inboxTask: {
        pl: 'Zadanie',
        cs: 'Úkol',
        sk: 'Úloha',
        en: 'Task',
        hu: 'Feladat',
    },
    transcriptionTrigger: {
        pl: 'Kliknij, aby przeczytać słowo.',
        cs: 'Klikněte pro přečtení slova.',
        sk: 'Kliknutím si prečítajte slovo.',
        en: 'Click to read the word.',
        hu: 'Kattints a szó elolvasásához.',
        ro: 'Click pentru a citi cuvântul.',
    },
    endModalHeader: {
        pl: 'Szanowni klienci,',
        cs: 'Vážení zákazníci,',
        sk: 'Vážení zákazníci,',
        en: 'Dear customers,',
        hu: 'Tisztelt ügyfeleink,',
        ro: 'Stimați clienți,',
    },
    endModalParagraph1: {
        pl: 'z powodu likwidacji naszej firmy chcielibyśmy poinformować Państwa o szczegółach działania naszych usług w najbliższej przyszłości.',
        cs: 'z důvodu likvidace naší společnosti bychom Vás rádi informovali o podrobnostech provozu našich služeb v blízké budoucnosti.',
        sk: 'z dôvodu likvidácie našej spoločnosti by sme Vás radi informovali o podrobnostiach prevádzky našich služieb v blízkej budúcnosti.',
        en: 'due to the liquidation of our company, we would like to inform you about the details of the operation of our services in the near future.',
        hu: 'cégünk felszámolása miatt szeretnénk tájékoztatni Önöket szolgáltatásaink működésének részleteiről a közeljövőben.',
        ro: 'datorită lichidării companiei noastre, dorim să vă informăm cu privire la detaliile funcționării serviciilor noastre în viitorul apropiat.',
    },
    endModalParagraph2: {
        pl: 'Dział obsługi klienta będzie dostępny do 21.03.2025 pod adresem kzs@polyglot-edu.eu.',
        cs: 'Zákaznické oddělení bude k dispozici do 21.03.2025  na adrese kzs@polyglot-edu.eu.',
        sk: 'Oddelenie služieb zákazníkom bude k dispozícii do 21.03.2025 na adrese ksz@polyglot-edu.eu.',
        en: 'The customer service department will be available until 21.03.2025 at kzs@polyglot-edu.eu.',
        hu: 'Az ügyfélszolgálati osztály 2025.02.14-ig áll rendelkezésre a következő címen: kzs@polyglot-edu.eu.',
        ro: 'Departamentul de servicii pentru clienți va fi disponibil până la 21.03.2025 la bsc@polyglot-edu.eu.',
    },
    endModalParagraph3: {
        pl: 'Platforma e-learningowa będzie aktywna do 9.03.2025.',
        cs: 'E-learningová platforma bude aktivní do 9.03.2025.',
        sk: 'Platforma elektronického vzdelávania bude aktívna do 9.03.2025.',
        en: 'The e-learning platform will be active until 9.03.2025.',
        hu: 'Az e-learning platform 2025.01.31-ig lesz elérhető.',
        ro: 'Platforma de e-learning va fi activă până la 9.03.2025.',
    },
    close: {
        pl: 'Zamknij',
        cs: 'Zavřít',
        sk: 'Zavrieť',
        en: 'Close',
        hu: 'Bezárás',
        ro: 'Închide',
    },
};
